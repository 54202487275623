.App {
  text-align: center;
}

.App-logo {
  width: 60vw;
  pointer-events: none;
}

.App-body {
  width: 60vw;
}

.App-header {
  background-color: #15804d;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header.national {
  background-color: #00703c;
}

.App-header.alamo {
  background-color: #0161a9;
  color: #dad419;
}

.App-link {
  color: #61dafb;
}
